.globalFooter {
    margin: 60px 60px 0;
    padding-top: 30px;
    border-top: 1px solid #acacac;
    @include SP{
        margin: 30px 15px 0;
    }
    ul{
        display: flex;
        justify-content: space-around;
    }
    .copyright{
        padding: 20px 0;
        border-top: 1px solid #acacac;
        text-align: center;
        margin-top: 30px;
        font-size: 12px;
        line-height: 2;
        @include SP{
            font-size: 10px;
        }
    }
}