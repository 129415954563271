#top{
	margin-top: 40px;
	@include SP{
		margin-top: 20px;
		padding: 0 15px;
	}
	.searchBox{
		display: flex;
		align-items: center;
        justify-content: center;
        @include SP{
            flex-wrap: wrap;
        }
		input[type=text]{
			display: block;
			width: 100%;
			padding: 5px;
			margin: 0;
			border: 1px solid #acacac;
			border-radius:0;
			font-family: $baseFontFamily;
            @include PC{
                max-width: 300px;
            }
            @include SP{
                margin-bottom: 15px;
            }
			&:focus{
				outline: none;
			}
		}
		button[type=submit]{
			font-family: $baseFontFamily;
			display: block;
			padding: 5px;
			background-color: #efefef;
			border: 1px solid #acacac;
			cursor: pointer;
            @include PC{
                margin-left: 10px;
            }
		}

		.searchOption{
			margin-left: 10px;
			font-size: 12px;
		}
	}
	.searchOptions{
		display: none;
		background-color: #cccccc;
		margin: 30px 60px 0;
		padding: 20px 60px;
        @include SP{
            margin: 30px 15px 0;
            padding: 20px 15px;
        }
		&.active{
			display: block;
		}
		p{
            line-height: 1.5;
            @include PC{
                font-size: 14px;
                text-align: center;
            }
            @include SP{
                font-size: 12px;
            }
		}
		ul{
			display: flex;
			flex-wrap: wrap;
			li{
				margin-top: 20px;
				margin-right: 20px;
				input[type=checkbox]{
					margin: 0;
					margin-right: 5px;
					border: 1px solid #acacac;
					border-radius:0;
					&:focus{
						outline: none;
					}
				}
				label{
					cursor: pointer;
				}
			}
		}
	}
    .nothing{
        margin-top: 40px;
        text-align: center;
        @include PC{
			padding: 0 60px;
		}
		@include SP{
			margin-top: 20px;
		}
    }
	.workLists{
		margin-top: 40px;
		@include PC{
			padding: 0 60px;
		}
		@include SP{
			margin-top: 20px;
		}
		li{
			padding-left: 20px;
			position: relative;
			line-height: 2;
			&::before{
				content: "";
				display: block;
				width: 6px;
				height: 6px;
				background-color: #333;
				border-radius: 50%;
				position: absolute;
				left: 0;
				top: 12px;
			}
			&:not(:first-child){
				margin-top: 20px;
			}
			a{
				&:first-of-type{
					font-size: 20px;
					@include SP{
						font-size: 18px;
					}
				}
				&:nth-of-type(2){
					&::after{
						content: ",";
					}
				}
				&:not(:first-of-type){
					font-style: italic;
				}
			}
		}
	}
}
