#notfound{
    margin: 20px 60px;
    .title{
        background-color: #a0b8c8;
        padding: 10px 20px;
        font-size: 20px;
    }
    .message{
        padding: 20px 20px;
    }
}