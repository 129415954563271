#about{
    margin: 20px auto 0;
    max-width: 1000px;
    padding: 0 100px;
    @include SP{
        padding: 0 15px;
    }
    .title{
        text-align: center;
        font-size: 30px;
        margin-bottom: 40px;
    }
    .subtitle{
        font-size: 24px;
        padding-bottom: 5px;
        border-bottom: 1px solid #acacac;
        margin:40px 0 20px;
    }
    .message{
        margin-bottom: 10px;
        line-height: 2;
    }
}
